require('./bootstrap');

$('.login-form #username').on('input',function(e){
    if( $(this).val() == 'test' ){
        $('.login-email2').slideDown();
    } else {
        if( $('.login-email2').is(":visible") ){
            $('.login-email2').slideUp();
        }
    }
});